/**
 * Nosy Component Sections - Events Row
 *
 * @export EventsRow
 */
import { Divider, Flex, Heading, LinkBox, LinkOverlay, SimpleGrid, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import Image from '../image/image'
import Link from '../link/link'

const EventsRow = ( { heading = 'Events', events = [] } ) => {
    // console.log( '🦄 ~ file: events-row.js ~ line 13 ~ EventsRow ~ events', { events } )

    const imageWidth = useBreakpointValue( { 
        base: 147, 
        sm: 156,
        md: 164,
        lg: 140
    } )

    return (
        <React.Fragment>
            <Flex
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                pb={6}>
                <Heading
                    color='gray.100'>
                    {heading}
                </Heading>
                <Flex flex='1' ms={10}>
                    <Divider borderColor='whiteAlpha.300' />
                </Flex>
            </Flex>

            {events && (
                <SimpleGrid 
                    columns={[1, 2]} 
                    spacing={[4, 6, 8, 20]}>
                    {events.map( ( { node } ) => (
                        <LinkBox 
                            key={node.id}
                            role='group'>
                            <Stack 
                                direction='row' 
                                height='full'
                                spacing={8}
                                mt={10}
                                pb={10}>
                                {node.mainImage && (
                                    <Image
                                        imageData={node.mainImage}
                                        width={imageWidth}
                                        height={140} />
                                )}
                                <LinkOverlay
                                    as={Link}
                                    to={`/${node.slug.current}/`}
                                    _hover={{ color: 'brand_secondary.500' }}>
                                    <Heading
                                        as='h2'
                                        color='gray.100'
                                        fontSize={['lg', 'xl', 'xl', '2xl']}
                                        transition='all 0.343s'
                                        _groupHover={{
                                            color: 'brand_secondary.500'
                                        }}
                                        mb={4}>
                                        {node.title}
                                    </Heading>
                                    {node.seo && (
                                        <Text 
                                            fontSize='md' 
                                            maxWidth='2xl' 
                                            mx='auto' 
                                            color='gray.400'>
                                            {node.seo.seoDescription}
                                        </Text>
                                    )}
                                </LinkOverlay>
                            </Stack>
                        </LinkBox>
                    )
                    )}
                </SimpleGrid>
            )}
            
        </React.Fragment>
    )

}

export default EventsRow