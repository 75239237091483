/**
 * Nosy Component Sections - Blog Row
 *
 * @export BlogRow
 */
import {
    Divider,
    Flex,
    Heading,
    LinkBox,
    LinkOverlay, SimpleGrid, Stack,
    Text,
    useBreakpointValue
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import React from 'react'
import Image from '../../components/image/image'
import Link from '../../components/link/link'
import { excerptSerializer } from '../../serializers'
import ForwardArrow from '../foward-arrow/forward-arrow'

const BlogRow = ( { heading = 'Blog', posts = [] } ) => {

    const imageWidth = useBreakpointValue( { 
        base: 447, 
        sm: 356,
        md: 356,
        lg: 300,
        xl: 347
    } )
    
    return (
        <React.Fragment>
            <Flex
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                pb={6}>
                <Heading
                    color='gray.100'>
                    {heading}
                </Heading>
                <Flex flex='1' mx={10}>
                    <Divider borderColor='whiteAlpha.300' />
                </Flex>
                <Link
                    to={'/blog/'}
                    transition='all 0.343s'
                    color='gray.100'
                    _hover={{ color: 'brand_secondary.500' }}>
                    <Stack direction='row' spacing={4} alignItems='center'>
                        <Text fontSize='xl'>
                            View Blog
                        </Text>
                        <ForwardArrow 
                            color='brand_secondary.500'
                            transition='all 0.343s'
                            width='21px' 
                            height='24px' />
                    </Stack>
                </Link>

            </Flex>
            <SimpleGrid 
                columns={[1, 2, 3]} 
                spacing={[4, 6, 8, 10]}>
                {posts.map( ( { node } ) => (
                    <LinkBox 
                        key={node.id}
                        role='group'>
                        <Stack
                            direction='column'
                            height='full'
                            spacing={8}
                            mt={10}
                            pb={10}>
                            {Image && (
                                <Image
                                    imageData={node.mainImage}
                                    height={280}
                                    width={imageWidth}
                                    sx={{
                                        width: 'full'
                                    }} />
                            )}
                            <LinkOverlay
                                as={Link}
                                to={`/blog/${node.slug.current}/`}>
                                <Heading
                                    as='h3'
                                    color='gray.100'
                                    fontSize={['lg', 'xl', '2xl', '3xl']}
                                    transition='all 0.343s'
                                    _groupHover={{
                                        color: 'brand_secondary.500'
                                    }}>
                                    {node.title}
                                </Heading>
                            </LinkOverlay>
                            <PortableText
                                value={node.excerpt}
                                components={excerptSerializer} />
                        </Stack>
                    </LinkBox>
                ) )}
            </SimpleGrid>
        </React.Fragment>
    )
}

export default BlogRow
