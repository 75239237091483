/**
 * Nosy Component Sections - Work Row
 *
 * @export WorkRow
 */
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Stack,
    Tag,
    Text,
    useBreakpointValue,
    Wrap,
    WrapItem
} from '@chakra-ui/react'
import React from 'react'
import Image from '../../components/image/image'
import Link from '../../components/link/link'

const WorkRow = ( { heading = '', work = [] } ) => {
    // console.log( '🦄 ~ file: work-row.js ~ line 22 ~ WorkRow ~ work', { work } )

    const imageWidth = useBreakpointValue( {
        base: 447,
        sm: 356,
        md: 356,
        lg: 300,
        xl: 347
    } )

    // Bail early if work is empty
    if ( Array.isArray( work ) && work.length > 0 ) {
        return (
            <React.Fragment>
                <Flex
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    py={6}
                    borderBottom='1px'
                    borderColor='gray.600'>
                    <Heading color='gray.100'>{heading}</Heading>
                </Flex>
                <SimpleGrid columns={[1, 2, 3]} spacing={[4, 6, 8, 10]}>
                    {work.map( ( node ) => (
                        <LinkBox 
                            key={node.id}
                            role='group'>
                            <Stack
                                direction='column'
                                height='full'
                                spacing={8}
                                mt={10}
                                pb={10}>
                                {node.featureImage && (
                                    <Image
                                        imageData={node.featureImage}
                                        width={imageWidth}
                                        height={480}
                                        sx={{
                                            width: 'full'
                                        }} />
                                )}
                                <LinkOverlay
                                    as={Link}
                                    to={
                                        node.slug
                                            ? `/work/${node.slug.current}/`
                                            : '#'
                                    }>
                                    <Heading
                                        as='h2'
                                        fontSize={['lg', 'xl', '2xl', '3xl']}
                                        color='gray.100'
                                        transition='all 0.343s'
                                        _groupHover={{
                                            color: 'brand_secondary.500'
                                        }}
                                        mb={4}>
                                        {node.title}
                                    </Heading>
                                    <Text
                                        fontSize='md'
                                        maxWidth='2xl'
                                        mx='auto'
                                        color='gray.400'>
                                        {node.heading}
                                    </Text>
                                </LinkOverlay>
                                <Box pb={6}>
                                    {node.discipline && (
                                        <Wrap spacing={2}>
                                            {node.discipline
                                                .sort()
                                                .map( ( discipline ) => (
                                                    <WrapItem
                                                        key={discipline.id}>
                                                        <Tag
                                                            color='gray.200'
                                                            backgroundColor='whiteAlpha.300'>
                                                            {discipline.title}
                                                        </Tag>
                                                    </WrapItem>
                                                ) )}
                                        </Wrap>
                                    )}
                                </Box>
                            </Stack>
                        </LinkBox>
                    ) )}
                </SimpleGrid>
                <Center mt={10}>
                    <Button
                        as={Link}
                        to='/work/'
                        size='lg'
                        variant='brand-outline'>
                        View work
                    </Button>
                </Center>
            </React.Fragment>
        )
    } else {
        return null
    }
}

export default WorkRow
