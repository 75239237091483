/**
 * Nosy Forward Arrow
 *
 * @export ForwardArrow
 */
import { chakra } from '@chakra-ui/react'
import React from 'react'
 
const ForwardArrow = ( props ) => (
    <chakra.svg 
        xmlns='http://www.w3.org/2000/svg' 
        viewBox='0 0 44.9 44.9'
        color='brand_grayscale.white'
        {...props}>
        <path 
            fill='currentColor'
            d='M40.4,18L25.2,2.8l-3,3l14.6,14.5H2.1v4.2h34.7L22.2,39.1l3,3l15.2-15.2C42.8,24.5,42.8,20.5,40.4,18z' />
    </chakra.svg>
)
 
export default ForwardArrow