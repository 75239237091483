/**
 * Nosy Component Sections - Team Grid
 *
 * @export TeamGrid
 */
import {
    Box,
    Divider,
    Flex,
    Heading,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue
} from '@chakra-ui/react'
import React from 'react'
import ForwardArrow from '../../components/foward-arrow/forward-arrow'
import Image from '../../components/image/image'
import Link from '../../components/link/link'

const TeamGrid = ( { heading = 'Team', team = [] } ) => {
    const imageWidths = useBreakpointValue( {
        base: 447,
        sm: 356,
        md: 300,
        lg: 347
    } )

    return (
        <React.Fragment>
            <Flex
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                pb={12}>
                <Heading color='gray.100'>{heading}</Heading>
                <Flex flex='1' ms={10}>
                    <Divider borderColor='whiteAlpha.300' />
                </Flex>
            </Flex>

            {team && (
                <SimpleGrid
                    // my={[24, 40, 64]}
                    columns={[1, 2, 3]}
                    spacing={[4, 6, 8, 10]}>
                    {team.map( ( { node } ) => (
                        <LinkBox key={node.id}>
                            <Box position='relative' role='group'>
                                <Image
                                    imageData={node.mainImage}
                                    loading='eager'
                                    height={480}
                                    width={imageWidths}
                                    sx={{
                                        width: 'full'
                                    }} />
                                <LinkOverlay
                                    as={Link}
                                    to={`/team/${node.slug.current}/`}>
                                    <Box
                                        px={8}
                                        pt={20}
                                        pb={4}
                                        visibility={['visible', null, 'hidden']}
                                        transition='all .343s'
                                        width='full'
                                        bgGradient='linear(to-b, transparent, brand_grayscale.darker)'
                                        position='absolute'
                                        bottom={0}
                                        opacity={[1, null, 0]}
                                        _groupHover={{
                                            visibility: 'visible',
                                            opacity: 1
                                        }}>
                                        <Stack
                                            direction='column'
                                            spacing={2}
                                            opacity={[1, null, 0]}
                                            transform={[
                                                null,
                                                null,
                                                'translateY(31px)'
                                            ]}
                                            transition='all .343s'
                                            transitionDelay='.25s'
                                            _groupHover={{
                                                transform: 'translateY(0)',
                                                opacity: 1
                                            }}>
                                            <Heading
                                                as='h4'
                                                color='brand_secondary.500'
                                                fontSize={['lg', 'xl', '2xl']}>
                                                {node.name}
                                            </Heading>
                                            <Stack
                                                direction='row'
                                                justifyContent='space-between'
                                                alignItems='center'>
                                                <Text color='gray.100'>{node.role}</Text>
                                                <ForwardArrow
                                                    color='brand_secondary.500'
                                                    transition='all 0.343s'
                                                    width='14px'
                                                    height='16px' />
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </LinkOverlay>
                            </Box>
                        </LinkBox>
                    ) )}
                </SimpleGrid>
            )}
        </React.Fragment>
    )
}

export default TeamGrid
