/**
 * Nosy Team
 *
 * @export Team
 */
import {
    Box,
    Image as ChakraImage,
    Container,
    Flex,
    Heading,
    Stack,
    Text,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../components/layout/layout'
import { TeamGrid } from '../../components/sections'
import SEO from '../../components/seo/seo'
import nosyMarkDoodleGif from '../../images/nosy-doodle.gif'
import { mapEdgesToNodes } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_TEAM_INDEX_QUERY($ids: [String]) {
        allSanityTeam(
            filter: { id: { in: $ids } }
            sort: { order: ASC, fields: name }
        ) {
            totalCount
            edges {
                node {
                    id
                    name
                    role
                    group
                    slug {
                        current
                    }
                    bio: _rawBio(resolveReferences: { maxDepth: 10 })
                    mainImage {
                        ...ImageWithPreview
                    }
                }
            }
        }
    }
`
const TeamIndex = ( props ) => {
    const { data = {} } = props
    const team = mapEdgesToNodes( data.allSanityTeam )

    // Divide team into specified groups
    const creativeTeam = team.filter( ( { node } ) => node.group === 'creative-team' )
    // const advisoryBoard = team.filter( ( { node } ) => node.group === 'advisory-board' )
    // const creativePartners = team.filter( ( { node } ) => node.group === 'creative-partners' )

    return (
        <Layout>
            <SEO
                title='Connect, explore, and grow'
                description='Not ones to shy away from having fun, we cultivate a creative environment for the good of our minds, and our work.' />
            <Container maxWidth='6xl'>
                <Stack direction={['column-reverse', null, 'row']}>
                    <Stack direction='column' width={['full', null, '50%']}>
                        <Heading
                            as='h1'
                            color='gray.100'
                            fontSize={['4xl', '5xl', '6xl']}
                            my={10}
                            fontWeight='extrabold'>
                            Connect, explore, and grow
                        </Heading>
                        <Text
                            fontFamily='Modelica-Medium'
                            fontSize={['lg', 'xl', '2xl']}
                            color='gray.100'
                            lineHeight='short'>
                            Not ones to shy away from having fun, we cultivate a
                            creative environment for the good of our minds, and
                            our work.
                        </Text>
                    </Stack>
                    <Flex
                        width={['full', null, '50%']}
                        justifyContent={['center', null, 'flex-end']}>
                        <ChakraImage
                            src={nosyMarkDoodleGif}
                            alt='Nosy Mark doodle'
                            boxSize={['140px', '160px', '180px']}
                            objectFit='cover'
                            my={10} />
                    </Flex>
                </Stack>
            </Container>
            
            <Container maxWidth='6xl' my={[24, 40, 64]}>
                <Stack direction='column' spacing={[24, 40, 64]}>
                    <Box>
                        <TeamGrid heading='Creative Team' team={creativeTeam} />
                    </Box>
                    {/*
                        <Box>
                            <TeamGrid heading='Creative Partners' team={creativePartners} />
                        </Box>
                    */}
                    {/*
                        <Box>
                            <TeamGrid heading='Advisory Board' team={advisoryBoard} />
                        </Box>
                    */}
                </Stack>
            </Container>
        </Layout>
    )
}

export default TeamIndex
